<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("BusinessType.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('BusinessType.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("BusinessType.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listBusinessType.length > 0">
          <thead>
          <tr>
            <th class="text-left">
              {{ $t("BusinessType.table.name") }}
            </th>
            <th class="text-left">
              {{ $t("BusinessType.table.created") }}
            </th>
            <th class="text-left">
              {{ $t("BusinessType.table.updated") }}
            </th>
            <th class="text-right">

            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item,index) in listBusinessType"
              :key="index"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.updated_at }}</td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchBusinessType"
        >
        </Pagination>
        <Loading v-if="isLoading"/>


        <ModalEdit fullscreen title="Edit Business Type">
          <template v-slot="{ close }">
            <EditBusinessType :businessType="businessType" @close="close" @success="fetchBusinessType()"/>
          </template>
        </ModalEdit>
        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteBusinessType :businessTypeId="businessTypeId" @close="close" @success="fetchBusinessType()"/>
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import EditBusinessType from "../../../components/businessType/EditBusinessType";
import Pagination from "../../../components/Paginate/Pagination";
import DeleteBusinessType from "../../../components/businessType/DeleteBusinessType";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import Loading from "../../../components/Loading";


export default {
  components: {
    EditBusinessType,
    Pagination,
    DeleteBusinessType,
    defaultTableNoResult,
    Loading
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 8,

      modalDeleteCompany: false,
      listBusinessType: [],
      businessType: {},
      businessTypeId: "",
      isLoading: false,
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchBusinessType();
    },
    filterBusinessType(businessTypeId) {
      return (
          this.listBusinessType.filter((item) => {
            return item.id == businessTypeId;
          }) [0] || {}
      )
    },
    onCreate() {
      this.$router.push({name: 'businessType.create'}).catch(() => {
      });
    },

    onEdit(businessTypeId) {
      this.businessType = {
        ...this.filterBusinessType(businessTypeId)
      };
      this.$store.commit("modalEdit_State", true);
    },
    onDeleted(businessTypeId) {
      this.businessTypeId = businessTypeId
      this.$store.commit("modalDelete_State", true);
    },

    fetchBusinessType() {
      this.isLoading = true;
      this.$axios.get(`admin/type-business`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.listBusinessType = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listBusinessType.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      })
    }
  },
  created() {
    this.fetchBusinessType();
  }
}
</script>

<style scoped lang="scss">
</style>
